<template>
  <div id="app">
    <div class="main_box weather_card">
      <div class="box_header">
        <div class="box_title">乐天气 一览无余</div>
        <div class="box_desc">一个可视化、高精度、智慧型天气网站，数据覆盖全球，使用起来也很简单</div>
      </div>
      <div class="box_bg">

      </div>
      <div class="footer_bg">
        <a href="//beian.miit.gov.cn" target="_blank">备案号：蜀ICP备2021011135号-2 </a> 成都多嘉裕网络科技有限公司
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'App'
}
</script>

<style>
html,
body {
  color: #333333;
  margin: 0;
  font-family: Alibaba PuHuiTi 2, Alibaba PuHuiTi 20;
  font-style: normal;
  text-transform: none
}

.main_box {
  height: 100vh;
  margin: 0 auto
}

.main_box.weather_card {
  background-color: #e9f5ff
}

.main_box.weather_card .box_bg {
  max-width: 960px;
  height: 600px;
  background-image: url(./assets/2.png);
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 auto;
  position: absolute;
  left: 50%;
  bottom: 16%;
  width: 100%;
  transform: translateX(-50%)
}


.main_box .box_header {
  position: absolute;
  left: 50%;
  top: 10%;
  text-align: center;
  transform: translateX(-50%)
}

.main_box .box_header .box_title {
  font-weight: normal;
  font-size: 36px;
  text-align: center
}

.main_box .box_header .box_desc {
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  margin-top: 20px
}

.main_box .footer_bg {
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40px;
  align-items: center;
  justify-content: center;
  background-color: #0870b1;
  color: #fff;
  font-size: 12px
}

.main_box .footer_bg a {
  color: #fff;
  text-decoration: none;
  margin-right: 10px
}
</style>
